exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cbd-index-js": () => import("./../../../src/pages/cbd/index.js" /* webpackChunkName: "component---src-pages-cbd-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-cwa-gateway-log-in-index-js": () => import("./../../../src/pages/cwa-gateway-log-in/index.js" /* webpackChunkName: "component---src-pages-cwa-gateway-log-in-index-js" */),
  "component---src-pages-gateway-index-js": () => import("./../../../src/pages/gateway/index.js" /* webpackChunkName: "component---src-pages-gateway-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-news-2014-12-just-how-to-produce-a-controversy-talk-index-mdx": () => import("./../../../src/pages/industry-news/2014/12/just-how-to-produce-a-controversy-talk/index.mdx" /* webpackChunkName: "component---src-pages-industry-news-2014-12-just-how-to-produce-a-controversy-talk-index-mdx" */),
  "component---src-pages-industry-news-2015-03-eta-transact-15-to-spotlight-future-payment-technologies-index-mdx": () => import("./../../../src/pages/industry-news/2015/03/eta-transact15-to-spotlight-future-payment-technologies/index.mdx" /* webpackChunkName: "component---src-pages-industry-news-2015-03-eta-transact-15-to-spotlight-future-payment-technologies-index-mdx" */),
  "component---src-pages-industry-news-2015-03-visa-steps-up-emv-education-with-city-by-city-tour-index-mdx": () => import("./../../../src/pages/industry-news/2015/03/visa-steps-up-emv-education-with-city-by-city-tour/index.mdx" /* webpackChunkName: "component---src-pages-industry-news-2015-03-visa-steps-up-emv-education-with-city-by-city-tour-index-mdx" */),
  "component---src-pages-industry-news-2015-03-why-payments-are-crucial-index-mdx": () => import("./../../../src/pages/industry-news/2015/03/why-payments-are-crucial/index.mdx" /* webpackChunkName: "component---src-pages-industry-news-2015-03-why-payments-are-crucial-index-mdx" */),
  "component---src-pages-industry-news-2015-05-alibaba-new-bar-code-2-index-mdx": () => import("./../../../src/pages/industry-news/2015/05/alibaba-new-bar-code-2/index.mdx" /* webpackChunkName: "component---src-pages-industry-news-2015-05-alibaba-new-bar-code-2-index-mdx" */),
  "component---src-pages-industry-news-2015-05-alibaba-new-bar-code-index-mdx": () => import("./../../../src/pages/industry-news/2015/05/alibaba-new-bar-code/index.mdx" /* webpackChunkName: "component---src-pages-industry-news-2015-05-alibaba-new-bar-code-index-mdx" */),
  "component---src-pages-industry-news-2015-05-apple-pay-progress-report-index-mdx": () => import("./../../../src/pages/industry-news/2015/05/apple-pay-progress-report/index.mdx" /* webpackChunkName: "component---src-pages-industry-news-2015-05-apple-pay-progress-report-index-mdx" */),
  "component---src-pages-industry-news-2015-05-chase-begins-issuing-emv-debit-cards-index-mdx": () => import("./../../../src/pages/industry-news/2015/05/chase-begins-issuing-emv-debit-cards/index.mdx" /* webpackChunkName: "component---src-pages-industry-news-2015-05-chase-begins-issuing-emv-debit-cards-index-mdx" */),
  "component---src-pages-industry-news-2015-05-mastercard-makes-600-million-acquisition-index-mdx": () => import("./../../../src/pages/industry-news/2015/05/mastercard-makes-600-million-acquisition/index.mdx" /* webpackChunkName: "component---src-pages-industry-news-2015-05-mastercard-makes-600-million-acquisition-index-mdx" */),
  "component---src-pages-industry-news-2015-05-real-life-mad-men-take-on-emv-marketing-2-index-mdx": () => import("./../../../src/pages/industry-news/2015/05/real-life-mad-men-take-on-emv-marketing-2/index.mdx" /* webpackChunkName: "component---src-pages-industry-news-2015-05-real-life-mad-men-take-on-emv-marketing-2-index-mdx" */),
  "component---src-pages-industry-news-2015-05-real-life-mad-men-take-on-emv-marketing-index-mdx": () => import("./../../../src/pages/industry-news/2015/05/real-life-mad-men-take-on-emv-marketing/index.mdx" /* webpackChunkName: "component---src-pages-industry-news-2015-05-real-life-mad-men-take-on-emv-marketing-index-mdx" */),
  "component---src-pages-industry-news-2015-08-debit-card-growth-data-durbin-ruling-index-mdx": () => import("./../../../src/pages/industry-news/2015/08/debit-card-growth-data-durbin-ruling/index.mdx" /* webpackChunkName: "component---src-pages-industry-news-2015-08-debit-card-growth-data-durbin-ruling-index-mdx" */),
  "component---src-pages-industry-news-2015-08-mobile-point-of-sale-to-grow-400-in-four-years-report-index-mdx": () => import("./../../../src/pages/industry-news/2015/08/mobile-point-of-sale-to-grow-400-in-four-years-report/index.mdx" /* webpackChunkName: "component---src-pages-industry-news-2015-08-mobile-point-of-sale-to-grow-400-in-four-years-report-index-mdx" */),
  "component---src-pages-industry-news-2015-10-b-2-b-commerce-tech-is-finally-catching-up-to-consumer-payments-index-mdx": () => import("./../../../src/pages/industry-news/2015/10/b2b-commerce-tech-is-finally-catching-up-to-consumer-payments/index.mdx" /* webpackChunkName: "component---src-pages-industry-news-2015-10-b-2-b-commerce-tech-is-finally-catching-up-to-consumer-payments-index-mdx" */),
  "component---src-pages-industry-news-2015-10-cwa-heading-to-wsaa-index-mdx": () => import("./../../../src/pages/industry-news/2015/10/cwa-heading-to-wsaa/index.mdx" /* webpackChunkName: "component---src-pages-industry-news-2015-10-cwa-heading-to-wsaa-index-mdx" */),
  "component---src-pages-industry-news-2015-11-in-a-first-for-debit-networks-shazam-adds-a-mobile-feature-to-shut-off-lost-or-stolen-cards-index-md": () => import("./../../../src/pages/industry-news/2015/11/in-a-first-for-debit-networks-shazam-adds-a-mobile-feature-to-shut-off-lost-or-stolen-cards/index.md" /* webpackChunkName: "component---src-pages-industry-news-2015-11-in-a-first-for-debit-networks-shazam-adds-a-mobile-feature-to-shut-off-lost-or-stolen-cards-index-md" */),
  "component---src-pages-industry-news-2015-11-tsys-and-ingenico-group-to-offer-new-semi-integrated-emv-solution-index-mdx": () => import("./../../../src/pages/industry-news/2015/11/tsys-and-ingenico-group-to-offer-new-semi-integrated-emv-solution/index.mdx" /* webpackChunkName: "component---src-pages-industry-news-2015-11-tsys-and-ingenico-group-to-offer-new-semi-integrated-emv-solution-index-mdx" */),
  "component---src-pages-industry-news-2015-12-datacap-releases-certified-tsys-us-emv-solution-for-point-of-sale-index-md": () => import("./../../../src/pages/industry-news/2015/12/datacap-releases-certified-tsys-us-emv-solution-for-point-of-sale/index.md" /* webpackChunkName: "component---src-pages-industry-news-2015-12-datacap-releases-certified-tsys-us-emv-solution-for-point-of-sale-index-md" */),
  "component---src-pages-industry-news-2015-12-how-can-merchants-close-the-emv-nfc-knowledge-gap-index-mdx": () => import("./../../../src/pages/industry-news/2015/12/how-can-merchants-close-the-emv-nfc-knowledge-gap/index.mdx" /* webpackChunkName: "component---src-pages-industry-news-2015-12-how-can-merchants-close-the-emv-nfc-knowledge-gap-index-mdx" */),
  "component---src-pages-industry-news-2016-02-1315-2-index-md": () => import("./../../../src/pages/industry-news/2016/02/1315-2/index.md" /* webpackChunkName: "component---src-pages-industry-news-2016-02-1315-2-index-md" */),
  "component---src-pages-industry-news-2016-02-nrf-applauds-senate-consideration-of-e-fairness-legislation-index-md": () => import("./../../../src/pages/industry-news/2016/02/nrf-applauds-senate-consideration-of-e-fairness-legislation/index.md" /* webpackChunkName: "component---src-pages-industry-news-2016-02-nrf-applauds-senate-consideration-of-e-fairness-legislation-index-md" */),
  "component---src-pages-industry-news-2016-03-vantiv-takes-a-pass-on-fantasy-sports-index-md": () => import("./../../../src/pages/industry-news/2016/03/vantiv-takes-a-pass-on-fantasy-sports/index.md" /* webpackChunkName: "component---src-pages-industry-news-2016-03-vantiv-takes-a-pass-on-fantasy-sports-index-md" */),
  "component---src-pages-industry-news-2016-04-visa-tweaks-chip-card-processing-protocol-says-emv-debit-cards-now-surpass-their-credit-brethren-index-md": () => import("./../../../src/pages/industry-news/2016/04/visa-tweaks-chip-card-processing-protocol-says-emv-debit-cards-now-surpass-their-credit-brethren/index.md" /* webpackChunkName: "component---src-pages-industry-news-2016-04-visa-tweaks-chip-card-processing-protocol-says-emv-debit-cards-now-surpass-their-credit-brethren-index-md" */),
  "component---src-pages-industry-news-2016-05-affirm-is-partnering-with-expedia-and-eventbrite-so-you-can-pay-for-experiences-over-time-index-md": () => import("./../../../src/pages/industry-news/2016/05/affirm-is-partnering-with-expedia-and-eventbrite-so-you-can-pay-for-experiences-over-time/index.md" /* webpackChunkName: "component---src-pages-industry-news-2016-05-affirm-is-partnering-with-expedia-and-eventbrite-so-you-can-pay-for-experiences-over-time-index-md" */),
  "component---src-pages-industry-news-2018-11-pay-at-the-table-comes-to-a-restaurant-group-with-ingenico-devices-index-md": () => import("./../../../src/pages/industry-news/2018/11/pay-at-the-table-comes-to-a-restaurant-group-with-ingenico-devices/index.md" /* webpackChunkName: "component---src-pages-industry-news-2018-11-pay-at-the-table-comes-to-a-restaurant-group-with-ingenico-devices-index-md" */),
  "component---src-pages-industry-news-2018-12-12-ways-consumers-will-pay-and-be-paid-in-2019-and-beyond-index-md": () => import("./../../../src/pages/industry-news/2018/12/12-ways-consumers-will-pay-and-be-paid-in-2019-and-beyond/index.md" /* webpackChunkName: "component---src-pages-industry-news-2018-12-12-ways-consumers-will-pay-and-be-paid-in-2019-and-beyond-index-md" */),
  "component---src-pages-industry-news-2018-12-as-mobile-checkout-evolves-companies-like-scandit-find-a-place-in-payments-index-md": () => import("./../../../src/pages/industry-news/2018/12/as-mobile-checkout-evolves-companies-like-scandit-find-a-place-in-payments/index.md" /* webpackChunkName: "component---src-pages-industry-news-2018-12-as-mobile-checkout-evolves-companies-like-scandit-find-a-place-in-payments-index-md" */),
  "component---src-pages-industry-news-2018-12-pos-terminal-whats-that-index-md": () => import("./../../../src/pages/industry-news/2018/12/pos-terminal-whats-that/index.md" /* webpackChunkName: "component---src-pages-industry-news-2018-12-pos-terminal-whats-that-index-md" */),
  "component---src-pages-industry-news-2019-01-3029-2-index-md": () => import("./../../../src/pages/industry-news/2019/01/3029-2/index.md" /* webpackChunkName: "component---src-pages-industry-news-2019-01-3029-2-index-md" */),
  "component---src-pages-industry-news-2019-01-eta-expert-insights-imagining-the-future-of-mobile-payments-index-md": () => import("./../../../src/pages/industry-news/2019/01/eta-expert-insights-imagining-the-future-of-mobile-payments/index.md" /* webpackChunkName: "component---src-pages-industry-news-2019-01-eta-expert-insights-imagining-the-future-of-mobile-payments-index-md" */),
  "component---src-pages-industry-news-2019-02-payments-analytics-highlight-valentines-day-spending-trends-index-md": () => import("./../../../src/pages/industry-news/2019/02/payments-analytics-highlight-valentines-day-spending-trends/index.md" /* webpackChunkName: "component---src-pages-industry-news-2019-02-payments-analytics-highlight-valentines-day-spending-trends-index-md" */),
  "component---src-pages-industry-news-2019-04-2019-trends-in-payments-for-small-businesses-index-md": () => import("./../../../src/pages/industry-news/2019/04/2019-trends-in-payments-for-small-businesses/index.md" /* webpackChunkName: "component---src-pages-industry-news-2019-04-2019-trends-in-payments-for-small-businesses-index-md" */),
  "component---src-pages-industry-news-2019-05-do-you-really-need-an-emv-chip-card-terminal-index-md": () => import("./../../../src/pages/industry-news/2019/05/do-you-really-need-an-emv-chip-card-terminal/index.md" /* webpackChunkName: "component---src-pages-industry-news-2019-05-do-you-really-need-an-emv-chip-card-terminal-index-md" */),
  "component---src-pages-industry-news-2019-06-new-eta-report-the-state-of-mobile-payments-in-2019-index-md": () => import("./../../../src/pages/industry-news/2019/06/new-eta-report-the-state-of-mobile-payments-in-2019/index.md" /* webpackChunkName: "component---src-pages-industry-news-2019-06-new-eta-report-the-state-of-mobile-payments-in-2019-index-md" */),
  "component---src-pages-industry-news-2019-06-top-payment-processing-trends-making-waves-in-2019-index-md": () => import("./../../../src/pages/industry-news/2019/06/top-payment-processing-trends-making-waves-in-2019/index.md" /* webpackChunkName: "component---src-pages-industry-news-2019-06-top-payment-processing-trends-making-waves-in-2019-index-md" */),
  "component---src-pages-industry-news-2019-08-entrepreneurs-share-7-smart-reasons-they-use-business-credit-cards-index-md": () => import("./../../../src/pages/industry-news/2019/08/entrepreneurs-share-7-smart-reasons-they-use-business-credit-cards/index.md" /* webpackChunkName: "component---src-pages-industry-news-2019-08-entrepreneurs-share-7-smart-reasons-they-use-business-credit-cards-index-md" */),
  "component---src-pages-industry-news-2019-08-mastercard-mdri-mastercard-dispute-resolution-initiative-index-md": () => import("./../../../src/pages/industry-news/2019/08/mastercard-mdri-mastercard-dispute-resolution-initiative/index.md" /* webpackChunkName: "component---src-pages-industry-news-2019-08-mastercard-mdri-mastercard-dispute-resolution-initiative-index-md" */),
  "component---src-pages-industry-news-2019-08-visa-vcr-visa-claims-resolution-allocation-and-collaboration-process-index-md": () => import("./../../../src/pages/industry-news/2019/08/visa-vcr-visa-claims-resolution-allocation-and-collaboration-process/index.md" /* webpackChunkName: "component---src-pages-industry-news-2019-08-visa-vcr-visa-claims-resolution-allocation-and-collaboration-process-index-md" */),
  "component---src-pages-industry-news-2019-09-visa-report-3-7-million-merchant-locations-accept-emv-cards-index-md": () => import("./../../../src/pages/industry-news/2019/09/visa-report-3-7-million-merchant-locations-accept-emv-cards/index.md" /* webpackChunkName: "component---src-pages-industry-news-2019-09-visa-report-3-7-million-merchant-locations-accept-emv-cards-index-md" */),
  "component---src-pages-industry-news-2019-10-stricter-visa-rules-for-trial-offers-and-promotions-to-take-effect-next-year-index-md": () => import("./../../../src/pages/industry-news/2019/10/stricter-visa-rules-for-trial-offers-and-promotions-to-take-effect-next-year/index.md" /* webpackChunkName: "component---src-pages-industry-news-2019-10-stricter-visa-rules-for-trial-offers-and-promotions-to-take-effect-next-year-index-md" */),
  "component---src-pages-industry-news-2019-11-are-millennials-and-gen-z-ditching-credit-cards-for-mobile-payment-apps-index-md": () => import("./../../../src/pages/industry-news/2019/11/are-millennials-and-gen-z-ditching-credit-cards-for-mobile-payment-apps/index.md" /* webpackChunkName: "component---src-pages-industry-news-2019-11-are-millennials-and-gen-z-ditching-credit-cards-for-mobile-payment-apps-index-md" */),
  "component---src-pages-industry-news-2019-12-mobile-payments-for-small-businesses-is-your-business-field-ready-index-md": () => import("./../../../src/pages/industry-news/2019/12/mobile-payments-for-small-businesses-is-your-business-field-ready/index.md" /* webpackChunkName: "component---src-pages-industry-news-2019-12-mobile-payments-for-small-businesses-is-your-business-field-ready-index-md" */),
  "component---src-pages-industry-news-2019-12-new-data-millennials-were-black-fridays-big-spenders-in-2019-index-md": () => import("./../../../src/pages/industry-news/2019/12/new-data-millennials-were-black-fridays-big-spenders-in-2019/index.md" /* webpackChunkName: "component---src-pages-industry-news-2019-12-new-data-millennials-were-black-fridays-big-spenders-in-2019-index-md" */),
  "component---src-pages-industry-news-2020-01-how-to-improve-your-credit-score-index-md": () => import("./../../../src/pages/industry-news/2020/01/how-to-improve-your-credit-score/index.md" /* webpackChunkName: "component---src-pages-industry-news-2020-01-how-to-improve-your-credit-score-index-md" */),
  "component---src-pages-industry-news-2020-02-report-changes-in-visas-interchange-rate-schedule-coming-this-year-index-md": () => import("./../../../src/pages/industry-news/2020/02/report-changes-in-visas-interchange-rate-schedule-coming-this-year/index.md" /* webpackChunkName: "component---src-pages-industry-news-2020-02-report-changes-in-visas-interchange-rate-schedule-coming-this-year-index-md" */),
  "component---src-pages-industry-news-2020-03-united-airlines-chase-and-visa-announce-multi-year-extension-of-united-mileageplus-credit-card-program-index-md": () => import("./../../../src/pages/industry-news/2020/03/united-airlines-chase-and-visa-announce-multi-year-extension-of-united-mileageplus-credit-card-program/index.md" /* webpackChunkName: "component---src-pages-industry-news-2020-03-united-airlines-chase-and-visa-announce-multi-year-extension-of-united-mileageplus-credit-card-program-index-md" */),
  "component---src-pages-industry-news-2020-04-coronavirus-payments-guide-everything-you-need-to-know-about-switching-to-online-phone-payments-index-md": () => import("./../../../src/pages/industry-news/2020/04/coronavirus-payments-guide-everything-you-need-to-know-about-switching-to-online-phone-payments/index.md" /* webpackChunkName: "component---src-pages-industry-news-2020-04-coronavirus-payments-guide-everything-you-need-to-know-about-switching-to-online-phone-payments-index-md" */),
  "component---src-pages-industry-news-2020-05-how-to-protect-your-credit-score-during-covid-19-index-md": () => import("./../../../src/pages/industry-news/2020/05/how-to-protect-your-credit-score-during-covid-19/index.md" /* webpackChunkName: "component---src-pages-industry-news-2020-05-how-to-protect-your-credit-score-during-covid-19-index-md" */),
  "component---src-pages-industry-news-2020-06-discover-says-fewer-people-are-opting-to-defer-credit-card-payments-index-md": () => import("./../../../src/pages/industry-news/2020/06/discover-says-fewer-people-are-opting-to-defer-credit-card-payments/index.md" /* webpackChunkName: "component---src-pages-industry-news-2020-06-discover-says-fewer-people-are-opting-to-defer-credit-card-payments-index-md" */),
  "component---src-pages-industry-news-2020-07-mastercard-expands-online-search-tool-to-us-canada-index-md": () => import("./../../../src/pages/industry-news/2020/07/mastercard-expands-online-search-tool-to-us-canada/index.md" /* webpackChunkName: "component---src-pages-industry-news-2020-07-mastercard-expands-online-search-tool-to-us-canada-index-md" */),
  "component---src-pages-industry-news-2020-08-apples-mobeewave-deal-could-turn-iphones-into-no-dongle-pos-devices-just-not-right-away-index-md": () => import("./../../../src/pages/industry-news/2020/08/apples-mobeewave-deal-could-turn-iphones-into-no-dongle-pos-devices-just-not-right-away/index.md" /* webpackChunkName: "component---src-pages-industry-news-2020-08-apples-mobeewave-deal-could-turn-iphones-into-no-dongle-pos-devices-just-not-right-away-index-md" */),
  "component---src-pages-industry-news-2020-09-everything-you-need-to-know-about-contactless-payments-during-covid-19-index-md": () => import("./../../../src/pages/industry-news/2020/09/everything-you-need-to-know-about-contactless-payments-during-covid-19/index.md" /* webpackChunkName: "component---src-pages-industry-news-2020-09-everything-you-need-to-know-about-contactless-payments-during-covid-19-index-md" */),
  "component---src-pages-industry-news-2020-10-what-every-merchant-needs-to-know-about-business-credit-repair-index-md": () => import("./../../../src/pages/industry-news/2020/10/what-every-merchant-needs-to-know-about-business-credit-repair/index.md" /* webpackChunkName: "component---src-pages-industry-news-2020-10-what-every-merchant-needs-to-know-about-business-credit-repair-index-md" */),
  "component---src-pages-industry-news-2020-11-how-to-fight-and-prevent-credit-card-chargebacks-in-your-small-business-index-md": () => import("./../../../src/pages/industry-news/2020/11/how-to-fight-and-prevent-credit-card-chargebacks-in-your-small-business/index.md" /* webpackChunkName: "component---src-pages-industry-news-2020-11-how-to-fight-and-prevent-credit-card-chargebacks-in-your-small-business-index-md" */),
  "component---src-pages-industry-news-2020-12-omnichannel-to-omnipresent-how-retailers-should-navigate-the-social-landscape-to-drive-purchase-decisions-this-holiday-season-index-md": () => import("./../../../src/pages/industry-news/2020/12/omnichannel-to-omnipresent-how-retailers-should-navigate-the-social-landscape-to-drive-purchase-decisions-this-holiday-season/index.md" /* webpackChunkName: "component---src-pages-industry-news-2020-12-omnichannel-to-omnipresent-how-retailers-should-navigate-the-social-landscape-to-drive-purchase-decisions-this-holiday-season-index-md" */),
  "component---src-pages-industry-news-2021-01-the-spendemic-holiday-shopping-during-covid-19-index-md": () => import("./../../../src/pages/industry-news/2021/01/the-spendemic-holiday-shopping-during-covid-19/index.md" /* webpackChunkName: "component---src-pages-industry-news-2021-01-the-spendemic-holiday-shopping-during-covid-19-index-md" */),
  "component---src-pages-industry-news-2021-02-venmo-users-moves-beyond-person-to-person-transfers-index-md": () => import("./../../../src/pages/industry-news/2021/02/venmo-users-moves-beyond-person-to-person-transfers/index.md" /* webpackChunkName: "component---src-pages-industry-news-2021-02-venmo-users-moves-beyond-person-to-person-transfers-index-md" */),
  "component---src-pages-industry-news-2021-03-card-brank-links-index-md": () => import("./../../../src/pages/industry-news/2021/03/card-brank-links/index.md" /* webpackChunkName: "component---src-pages-industry-news-2021-03-card-brank-links-index-md" */),
  "component---src-pages-industry-news-2021-03-women-small-business-owners-are-optimistic-about-a-post-pandemic-world-index-md": () => import("./../../../src/pages/industry-news/2021/03/women-small-business-owners-are-optimistic-about-a-post-pandemic-world/index.md" /* webpackChunkName: "component---src-pages-industry-news-2021-03-women-small-business-owners-are-optimistic-about-a-post-pandemic-world-index-md" */),
  "component---src-pages-industry-news-2021-04-card-brand-changes-index-md": () => import("./../../../src/pages/industry-news/2021/04/card-brand-changes/index.md" /* webpackChunkName: "component---src-pages-industry-news-2021-04-card-brand-changes-index-md" */),
  "component---src-pages-industry-news-2021-04-its-time-for-merchants-to-enhance-the-customer-experience-with-bopis-and-curbside-pickup-offerings-index-md": () => import("./../../../src/pages/industry-news/2021/04/its-time-for-merchants-to-enhance-the-customer-experience-with-bopis-and-curbside-pickup-offerings/index.md" /* webpackChunkName: "component---src-pages-industry-news-2021-04-its-time-for-merchants-to-enhance-the-customer-experience-with-bopis-and-curbside-pickup-offerings-index-md" */),
  "component---src-pages-industry-news-2021-05-social-media-ecommerce-a-new-contender-in-shopping-wars-index-md": () => import("./../../../src/pages/industry-news/2021/05/social-media-ecommerce-a-new-contender-in-shopping-wars/index.md" /* webpackChunkName: "component---src-pages-industry-news-2021-05-social-media-ecommerce-a-new-contender-in-shopping-wars-index-md" */),
  "component---src-pages-industry-news-2021-10-card-brand-dispute-updates-index-md": () => import("./../../../src/pages/industry-news/2021/10/card-brand-dispute-updates/index.md" /* webpackChunkName: "component---src-pages-industry-news-2021-10-card-brand-dispute-updates-index-md" */),
  "component---src-pages-industry-news-2022-03-card-brand-changes-april-2022-index-md": () => import("./../../../src/pages/industry-news/2022/03/card-brand-changes-april-2022/index.md" /* webpackChunkName: "component---src-pages-industry-news-2022-03-card-brand-changes-april-2022-index-md" */),
  "component---src-pages-industry-news-2023-04-visa-business-news-april-2023-index-md": () => import("./../../../src/pages/industry-news/2023/04/visa-business-news-april-2023/index.md" /* webpackChunkName: "component---src-pages-industry-news-2023-04-visa-business-news-april-2023-index-md" */),
  "component---src-pages-industry-news-index-js": () => import("./../../../src/pages/industry-news/index.js" /* webpackChunkName: "component---src-pages-industry-news-index-js" */),
  "component---src-pages-merchants-cwa-gateway-index-js": () => import("./../../../src/pages/merchants/cwa-gateway/index.js" /* webpackChunkName: "component---src-pages-merchants-cwa-gateway-index-js" */),
  "component---src-pages-merchants-gateways-index-js": () => import("./../../../src/pages/merchants/gateways/index.js" /* webpackChunkName: "component---src-pages-merchants-gateways-index-js" */),
  "component---src-pages-merchants-index-js": () => import("./../../../src/pages/merchants/index.js" /* webpackChunkName: "component---src-pages-merchants-index-js" */),
  "component---src-pages-merchants-pos-solutions-index-js": () => import("./../../../src/pages/merchants/pos-solutions/index.js" /* webpackChunkName: "component---src-pages-merchants-pos-solutions-index-js" */),
  "component---src-pages-merchants-services-index-js": () => import("./../../../src/pages/merchants/services/index.js" /* webpackChunkName: "component---src-pages-merchants-services-index-js" */),
  "component---src-pages-merchants-support-faq-index-js": () => import("./../../../src/pages/merchants/support-faq/index.js" /* webpackChunkName: "component---src-pages-merchants-support-faq-index-js" */),
  "component---src-pages-merchants-terminals-index-js": () => import("./../../../src/pages/merchants/terminals/index.js" /* webpackChunkName: "component---src-pages-merchants-terminals-index-js" */),
  "component---src-pages-partner-program-index-js": () => import("./../../../src/pages/partner-program/index.js" /* webpackChunkName: "component---src-pages-partner-program-index-js" */),
  "component---src-pages-partner-program-refer-an-agent-index-js": () => import("./../../../src/pages/partner-program/refer-an-agent/index.js" /* webpackChunkName: "component---src-pages-partner-program-refer-an-agent-index-js" */),
  "component---src-pages-partner-program-sales-advantage-index-js": () => import("./../../../src/pages/partner-program/sales-advantage/index.js" /* webpackChunkName: "component---src-pages-partner-program-sales-advantage-index-js" */),
  "component---src-pages-partner-program-value-added-resellers-index-js": () => import("./../../../src/pages/partner-program/value-added-resellers/index.js" /* webpackChunkName: "component---src-pages-partner-program-value-added-resellers-index-js" */),
  "component---src-pages-veterinarians-index-js": () => import("./../../../src/pages/veterinarians/index.js" /* webpackChunkName: "component---src-pages-veterinarians-index-js" */),
  "component---src-pages-web-reporting-index-js": () => import("./../../../src/pages/web-reporting/index.js" /* webpackChunkName: "component---src-pages-web-reporting-index-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/CategoryPage.js" /* webpackChunkName: "component---src-templates-category-page-js" */)
}

